import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import RichText from '../components/RichText'

const Page = ({
  data: {
    contentfulContentPage: { title, content },
  },
}) => {
  return (
    <Layout>
      <Seo title={title} />
      {content && <RichText content={content} />}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($path: String) {
    contentfulContentPage(path: { eq: $path }) {
      path
      title
      content {
        raw
        references {
          ... on Node {
            ... on ContentfulNavButtonListing {
              __typename
              contentful_id
              name
              navButtons {
                buttonCta
                toPath
                color
                external
                externalUrl
                externalTargetBlank
                disabled
              }
            }
            ... on ContentfulNavCardListing {
              __typename
              contentful_id
              name
              navCards {
                cardTitle
                cardCta
                toPath
                image {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }
                external
                externalUrl
                externalTargetBlank
                comingSoon
                disabled
              }
            }
            ... on ContentfulNewsletterSignup {
              __typename
              contentful_id
              name
              inputPlaceholder
              cta
              errorMessage
              successMessage
            }
            ... on ContentfulEventListing {
              __typename
              contentful_id
              name
              events {
                name
                description {
                  description
                }
                start
                end
                buttonCta
                url
              }
            }
            ... on ContentfulBoredApeOutfitListing {
              __typename
              contentful_id
              boredApeOutfits {
                name
                fromDate
                toDate
                apeWithOutfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }

                outfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                  localFile {
                    id
                    publicURL
                  }
                }
              }
            }
            ... on ContentfulEmbeddedImage {
              __typename
              contentful_id
              alignment
              altText
              image {
                gatsbyImageData(width: 1280)
              }
            }
            ... on ContentfulNftListing {
              __typename
              contentful_id
              name
              nfts {
                title
                image {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }
                text
                linkUrl
                webLinkText
                webLinkUrl
                twitterLinkText
                twitterLinkUrl
                discordLinkText
                discordLinkUrl
                blogLinkText
                blogLinkUrl
              }
            }
            ... on ContentfulCannabisInfusedProduct {
              __typename
              contentful_id
              name
              ratio
              color
              description {
                description
              }
              alignment
              productImage {
                gatsbyImageData(width: 512)
              }
              graphImage {
                gatsbyImageData(width: 512)
              }
            }
            ... on ContentfulBoredApeOutfitDownloadButton {
              __typename
              contentful_id
              buttonCta
              boredApeOutfit {
                name
                fromDate
                toDate
                apeWithOutfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                }

                outfit {
                  gatsbyImageData(layout: CONSTRAINED, width: 512, height: 512)
                  localFile {
                    id
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
